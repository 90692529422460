import { openPopupWidget } from "react-calendly";
const timezoneOffset = new Date().getTimezoneOffset() / 60;

export function scheduleDiscoveryCall() {
  const url =
    timezoneOffset > 3
      ? "https://calendly.com/nanonets/discovery-call-us"
      : "https://calendly.com/nanonets/discovery-call";

  openPopupWidget({
    url,
    utm: {
      utmSource: window.location.href,
    },
  });
}

export function schedulePartnershipCall() {
  const url =
    timezoneOffset > 3
      ? "https://calendly.com/nanonets/partnerships"
      : "https://calendly.com/nanonets/partners";

  openPopupWidget({
    url,
    utm: {
      utmSource: window.location.href,
    },
  });
}

function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf("calendly") === 0;
}

typeof window !== "undefined" &&
  window.addEventListener("message", function (e) {
    if (isCalendlyEvent(e)) {
      if (e.data.event === "calendly.event_scheduled") {
        handleEvent(e.data);
      }
    }
  });

function handleEvent(data) {
  const personal_access_token =
    "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjE4MjM2MDY2LCJqdGkiOiI5ZTQ2MmNmMS00MzI0LTRjYzAtYTJkNy03ZDFhMTMyNzcxZGMiLCJ1c2VyX3V1aWQiOiJIRkdGSEtGSVkzTk9BUUxVIiwib3JnX3V1aWQiOiJBQ0NER0lOMk9FNUY0S0QzIn0.eowaBeSbnOuBGVKSQxwVjQDyPVj9Xss8vqqzFrvOlYY";
  const invitee_uri = data.payload.invitee.uri;
  const url = window.location.href;

  fetch(invitee_uri, {
    headers: {
      Authorization: `Bearer ${personal_access_token}`,
    },
  })
    .then((res) => {
      if (!res.ok)
        throw new Error("An error occured while fetching invitee details");
      return res.json();
    })
    .then((data) => {
      const email = data.resource.email;
      window.posthog.identify(email);
      window.posthog.people.set({ email });
      window.posthog.capture("calendly_call_setup", {
        email,
        url,
      });
    })
    .catch(console.error);
}
